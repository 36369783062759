import React from 'react'
import { LayoutV2 } from '../../components/common'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formWrapper: {
    maxWidth: '750px',
    height: '800px',
    border: '1px solid #c0c2c7',
    margin: 'auto',
    '& iframe': {
      width: '100%',
      height: '100%',
      border: 'none'
    }
  }
}))

const TermsOfServicePage = ({ data = {} }) => {
  const classes = useStyles()
  return (
    <LayoutV2 query={data} title="PGA.Coach">
      <Container style={{ marginTop: '100px', marginBottom: '100px' }}>
        <Box className={classes.formWrapper}>
          <iframe
            src="https://privacyportal.onetrust.com/webform/d026f30a-622a-40b3-b261-d78a6b839240/ae8a40ab-c486-4b0f-842f-07d3cdeaaf85"
            title="form"
          />
        </Box>
      </Container>
    </LayoutV2>
  )
}

export default TermsOfServicePage
